@-webkit-keyframes green-fade {
  from {
    background: rgb(93, 191, 68);
  }
  to {
    background: transparent;
  }
}

@-moz-keyframes green-fade {
  from {
    background: rgb(93, 191, 68);
  }
  to {
    background: transparent;
  }
}
@keyframes green-fade {
  from {
    background: rgb(93, 191, 68);
  }
  to {
    background: transparent;
  }
}

@-webkit-keyframes red-fade {
  from {
    background: #b54343;
  }
  to {
    background: transparent;
  }
}
@-moz-keyframes red-fade {
  from {
    background: #b54343;
  }
  to {
    background: transparent;
  }
}
@keyframes red-fade {
  from {
    background: #b54343;
  }
  to {
    background: transparent;
  }
}

@-webkit-keyframes white-fade {
  from {
    background: #fff;
  }
  to {
    background: transparent;
  }
}
@-moz-keyframes white-fade {
  from {
    background: #fff;
  }
  to {
    background: transparent;
  }
}
@keyframes white-fade {
  from {
    background: #fff;
  }
  to {
    background: transparent;
  }
}
